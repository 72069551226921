(function($) {
  // New Menu Burger Menu

  var Menu = {

    el: {
      ham: $('.burger_menu'),
      animate_burger: $('.burger_menu')
    },

    init: function () {
      Menu.bindUIactions();
    },

    bindUIactions: function () {
      Menu.el.ham
        .on(
          'click',
          function (event) {
            Menu.activateMenu(event);
            event.preventDefault();
          }
        );
    },

    activateMenu: function () {
      Menu.el.ham.toggleClass('menu_close');
      Menu.el.animate_burger.toggleClass('clicked');
      $('.mobile_menu').toggleClass('slide_open');
    }
  };

  Menu.init();


  $(".parent_link_items").click(function() {
    $(".parent_link_items").removeClass("parent_open");
    $(this).addClass("parent_open");
    $(".arrow").removeClass("open_arrow");
    $(this).find(".arrow").addClass("open_arrow");
    $(".dropdown_outer").removeClass("dropdown_active");
    $(".dropdown_outer").removeClass("fadeIn");
    $(this).find(".dropdown_outer").addClass("dropdown_active");
    $(this).find(".dropdown_outer").addClass("fadeIn");
  });

  $(".arrow").click(function() {
    $("parent_link_items").removeClass("parent_open");
    $(this).removeClass("open_arrow");
  });
})(jQuery); 