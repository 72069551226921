import 'owl.carousel';
import 'animate.css';

(function($) {


    if ($(".products")[0]) {
        $(document).ready(function () {
            var carousel = $(".products.owl-carousel");
            carousel.owlCarousel({
                autoplay: true,
                autoplayTimeout: 5000,
                autoplayHoverPause: true,
                loop: true,
                dots: true,
                nav: false, 
                responsiveClass: true,
                responsive: {
                0: {
                    items: 1
                },
                767: {
                    items: 2
                },
                992: { 
                    items: 4
                }
            }
            });
            $(".products_outer .owl_nav_products_carousel").on("click", function() {
            if ($(this).hasClass("prev")) {
                    $(".owl-carousel button.owl-prev").trigger("click");
                    console.log("clicked pre");
                } else if ($(this).hasClass("next")) {
                    $(".owl-carousel button.owl-next").trigger("click");
                    console.log("clicked next");
                } 
            });
        });
    }


    if ($(".testimonial")[0]) {
        $(document).ready(function () {
            var carousel = $(".testimonial.owl-carousel");
            carousel.owlCarousel({
                autoplay: true,
                autoplayTimeout: 5000,
                autoplayHoverPause: true,
                loop: true,
                dots: true,
                nav: false,
                items: 1,
            });
        });
    }


    // Top home carousel
    if ($(".banner_slider")[0]) {
        $(document).ready(function () {
            var carousel = $(".banner_slider .owl-carousel");
            carousel.owlCarousel({
                autoplay: true,
                autoplayTimeout: 5000,
                autoplayHoverPause: true, 
                loop: true,
                dots: true, 
                items: 1,
                mouseDrag: false,
                touchDrag: false,
                pullDrag: false,
                freeDrag: false
            });
        }); 
    }

})(jQuery); 