(function($) {

  if ('objectFit' in document.documentElement.style === false) { // browser doesn't support object fit

  // console.log("Is IE");

  let images = document.getElementsByTagName('IMG'); // gets all images in the page

  // console.log(images);
  // console.log(images.length);

  for (var i = 0; i < images.length; i++) {  

    // console.log("In loop");
    // console.log(images[i]);

    let parentContainer = images[i].parentElement;
    let hasContainerClass = (parentContainer.classList.contains('object_fit')) ? true : false; // check for the container class that we want to target 

    // console.log(hasContainerClass);
    
    if (hasContainerClass) {

      // console.log("Container class found");

      // do the magic
      let imageSrc = images[i].src;
      images[i].style.opacity = 0;

      // set the styles
      parentContainer.style.backgroundImage = 'url('+imageSrc+')';
      parentContainer.style.backgroundSize = 'cover';
      parentContainer.style.backgroundRepeat = 'no-repeat';
      parentContainer.style.backgroundPosition = '50% 50%'; 
    } else { 
      // console.log("Container class NOT found");
    }

  }
  }

})(jQuery);