(function($) {

  $('p:empty').remove();

  $("li.has_children").hover(
    function () {
      if ($(this).hasClass("active")) { 
        $(this).removeClass("active");
      } else {
        $(this).addClass("active");
      }
    },
    function () {
      if ($(this).hasClass("active")) {
        $(this).removeClass("active");
      } else {
        $(this).addClass("active");
      }
    }
  );

  $("document").ready(function() {
      setTimeout(function() {
          $(".page-template-page-coffee-shop .show_times_2").trigger('click');
      },10);
  });

  // Remove break from menu

  $(".mobile_menu .title").find('br').remove();

  // Banner slider 

  $(".title_wrapper").on("click", function () {
    //console.log("clicked");
    var slider_to_show = $(this).attr("data-attr");

    $(".banner_items").removeClass("active");
    $(".title_wrapper_slide").removeClass("active");

    $(".banner_items." + slider_to_show).addClass("active");
    $(".title_wrapper_slide." + slider_to_show).addClass("active");
  });


  // Mobile menu toggle

  $(".mobile_search").on("click", function () {
    $(".mobile_search_menu").toggleClass("mobile_search_menu_open");
    $(".mobile_menu").removeClass("slide_open");
  });

  $(".burger_menu").on("click", function () {
    $(".mobile_search_menu").removeClass("mobile_search_menu_open");
  });

  // Remove br from sub nav

  $(document).ready(function () {
    if ($(window).width() < 992) {
      $(".title_wrapper_slide .top_title h4").find('br').remove();
    }
  });


  $(document).ready(function() {
    $( "#shipping_method_0_flat_rate1").insertAfter("<p>fgfgfg</p>");
  });

  // Times bind the data attritbutes on the buttons with a class and also the element.


  $(document).ready(function (e) {
      $('.page-template-page-forest-adventure .show_times_3').click(); 
  });

  $(".lables_inner").on("click", function () {
    //console.log("clicked");
    var times_to_show = $(this).attr("data-attr");

    $(".times_inner").removeClass("active");
    $(".times_inner").removeClass("fadeIn");
    $(".lables_inner").removeClass("active");

    $(".times_inner." + times_to_show).addClass("active");
    $(".times_inner." + times_to_show).addClass("fadeIn");
    $(".lables_inner." + times_to_show).addClass("active");
  });

  // Show products information


  $(".labels_titles").on("click", function () {
    //console.log("clicked");
    var product_info_to_show = $(this).attr("data-attr");

    $(".product_content_areas").removeClass("active");
    $(".product_content_areas").removeClass("fadeIn");
    $(this).removeClass("active");

    $(".labels_titles." + product_info_to_show).addClass("active");

    $(".product_content_areas." + product_info_to_show).addClass("active");
    $(".product_content_areas." + product_info_to_show).addClass("fadeIn");
  });

  // Single events - tabs
  $(".tab_buttons>div").on("click", function () {
    var current_attr = $(this).attr("data-attr");
    // buttons
    $(".tab_buttons>div").removeClass("active");
    $(this).addClass("active");
    // areas
    $(".tab_area").removeClass("active");
    $("." + current_attr).addClass("active");
  });

  // Coffee shop - opening times
  $(".toggle_buttons>div").on("click", function () {
    var current_attr = $(this).attr("data-attr");
    // buttons
    $(".toggle_buttons>div").removeClass("active");
    $(this).addClass("active");
    // areas
    $(".times_outer").removeClass("active");
    $("." + current_attr).addClass("active");
  });

  $(document).on("input", "#tribe-tickets-meta_how-many-children_4291", function() {

    var multiplier = 0;

    if ($("input[value='Farm Party (£8 per head)']").is(":checked")) {
      multiplier = 8;
    }

    if ($("input[value='Forest Party (£12 per head)']").is(":checked")) {
      multiplier = 12;
    }

    if (multiplier > 0) {
      var current_val = $(this).val();
    
      var total_cost = current_val * multiplier;
    
      console.log(total_cost);
    
      $(".deposit_cost").remove();
      $(".total_cost").remove();

      $("<h2 class='deposit_cost'>Deposit: £50.00</h2>").insertAfter($(this).closest(".tribe-common-b1").next().next());
      $("<h2 class='total_cost'>Total: £" + total_cost + ".00</h2>").insertAfter($(this).closest(".tribe-common-b1").next().next());
    }
  });

  // FAQs

  $(".faq_block .question").on("click", function() {
    if ($(this).hasClass("active")) {
      $(this).removeClass("active");
      $(this).next().removeClass("active");
    } else {
      $(this).addClass("active");
      $(this).next().addClass("active");
    }
  });

})(jQuery);